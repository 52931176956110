<template>
    <div>
        <section class="box">
            <div class="box_inner">
                <div class="options">
                    <div class="options_content">
                        <div class="options_list options_list-all_desktop">
                            <button
                                v-for="gameCategory in gameCategories"
                                class="options_el"
                                type="button"
                                @click="scrollToCategory(gameCategory.id)"
                            >
                                <span>
                                    <img
                                        alt="game category icon"
                                        :src="gameCategory.logo"
                                        loading="lazy"
                                    />
                                    <span>{{ gameCategory.name }}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section
            v-for="gameCategory in gameCategories"
            class="box game-category"
        >
            <div class="box_inner">
                <div class="box_content">
                    <div class="type">
                        <div
                            :ref="`gameCategory${gameCategory.id}`"
                            :style="{
                                position: 'absolute',
                                height: `${scrollOffset}px`,
                                top: `-${scrollOffset}px`,
                                'pointer-events': 'none',
                            }"
                        ></div>
                        <div class="typeImg">
                            <img
                                loading="lazy"
                                :src="gameCategory.image"
                                :alt="gameCategory.name"
                            />
                        </div>
                        <div class="typeContent">
                            <h2>{{ gameCategory.name }}</h2>
                            <p>{{ gameCategory.description }}</p>
                        </div>
                    </div>
                    <h2 class="catalog-title">{{ most_popular_text }}</h2>
                    <div class="catalog">
                        <div
                            v-for="game in gameCategory.games"
                            class="catalog-el"
                        >
                            <div class="catalog-top">
                                <div class="catalog-img">
                                    <img
                                        loading="lazy"
                                        :src="game.logo"
                                        :alt="game.name"
                                    />
                                </div>
                                <a
                                    :href="game.url"
                                    class="catalog-btn"
                                >
                                    {{ play_now_text }}
                                </a>
                            </div>
                            <div class="catalog-name">
                                <a :href="game.url">{{ game.name }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="gameCategory.current_page < gameCategory.last_page"
                    class="box_bottom"
                >
                    <button
                        class="btn btn_clear load-more"
                        @click="nextPage(gameCategory.id)"
                    >
                        <span>{{ more_text }}</span>
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        initialData: String,
    },
    data() {
        const initialData = JSON.parse(this.initialData);

        return {
            scrollOffset: 132,
            rating_games_route: initialData?.rating_games_route ?? '',
            option_icon: initialData?.option_icon ?? '',
            most_popular_text: initialData?.most_popular_text ?? '',
            play_now_text: initialData?.play_now_text ?? '',
            more_text: initialData?.more_text ?? '',
            count_to_show: initialData?.count_to_show ?? 12,
            count_to_paginate: initialData?.count_to_paginate ?? 6,
            gameCategories: initialData?.gameCategories ?? [],
        };
    },
    created() {
        window.addEventListener('resize', this.windowResize);
        setTimeout(() => this.calculateScrollOffset(), 500);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        windowResize(e) {
            this.calculateScrollOffset();
        },
        calculateScrollOffset() {
            const header = document.getElementsByClassName('header').item(0);
            const gameCategory = document.getElementsByClassName('game-category').item(0);
            this.scrollOffset = header.offsetHeight + parseInt(window.getComputedStyle(gameCategory).marginTop)
        },
        scrollToCategory(game_category_id) {
            this.$refs[`gameCategory${game_category_id}`][0].scrollIntoView({behavior: 'smooth'});
        },
        nextPage(game_category_id) {
            const gameCategory = this.gameCategories.find((gameCategory) => gameCategory.id === game_category_id);

            const params = new URLSearchParams({
                per_page: this.count_to_paginate,
                page: gameCategory.current_page,
            });

            if (this.game_category_id) {
                params.set('game_category_id', gameCategory.id);
            }

            gameCategory.exclude_ids.forEach((id, index) => {
                params.append('exclude_ids[]', id);
            });

            const requestOptions = {
                method: 'get',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            };

            fetch(this.rating_games_route + '?' + params, requestOptions)
                .then((response) => response.json())
                .then((response) => {
                    response.data.forEach((game) => {
                        gameCategory.games.push(game);
                        gameCategory.exclude_ids.push(game.id);
                    });
                    gameCategory.current_page = response.meta.current_page;
                    gameCategory.last_page = response.meta.last_page;

                })
                .catch(error => console.log(error.message));
        },
    },
}
</script>
