<template>
    <div class="card_section">
        <h2 class="card_section-title">
            <img :src="title_icon" alt="available games icon">
            {{ available_games_text }}
        </h2>
        <div class="options">
            <div class="options_content">
                <div class="options_list options_list-all_desktop">
                    <button
                        type="button"
                        :class="{
                            options_el: true,
                            active: game_category_id === null,
                        }"
                        @click="setGameCategory(null)"
                    >
                        <span>{{ all_text }}</span>
                    </button>
                    <button
                        v-for="game_category in game_categories"
                        type="button"
                        :class="{
                            options_el: true,
                            active: game_category_id === game_category.id,
                            disabled: !isActive(game_category.id),
                        }"
                        :data-title="no_available_game_text"
                        @click="setGameCategory(game_category.id)"
                    >
                        <span>
                            <img
                                loading="lazy"
                                :src="game_category.logo ?? option_icon"
                                :alt="game_category.name"
                            />
                            <span>{{ game_category.name }}</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="catalog">
            <div
                v-for="game in games"
                class="catalog-el"
            >
                <div class="catalog-top">
                    <div class="catalog-img">
                        <img
                            loading="lazy"
                            :src="game.logo"
                            :alt="game.name"
                        />
                    </div>
                    <a :href="game.url" class="catalog-btn">{{ play_now_text }}</a>
                </div>
                <div class="catalog-name">
                    <a :href="game.url">{{ game.name }}</a>
                </div>
            </div>
        </div>
        <div
                class="cards_bottom"
                v-if="current_page < last_page"
        >
            <button
                    type="button"
                    class="cards_more load-more"
                    @click="loadMore"
            >
                <span>{{ more_text }}</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        initialData: String,
    },
    data() {
        const initialData = JSON.parse(this.initialData);

        return {
            game_category_id: null,
            developer_games_route: initialData?.developer_games_route ?? '',
            title_icon: initialData?.title_icon ?? '',
            option_icon: initialData?.option_icon ?? '',
            available_games_text: initialData?.available_games_text ?? '',
            play_now_text: initialData?.play_now_text ?? '',
            no_available_game_text: initialData?.no_available_game_text ?? '',
            all_text: initialData?.all_text ?? '',
            more_text: initialData?.more_text ?? '',
            game_categories: initialData?.game_categories ?? [],
            games: initialData?.games ?? [],
            current_page: initialData?.current_page ?? null,
            last_page: initialData?.last_page ?? null,
            active_game_categories: initialData?.active_game_categories ?? [],
        };
    },
    methods: {
        isActive(game_category_id) {
            return this.active_game_categories.map(game_category => game_category.id).includes(game_category_id);
        },
        setGameCategory(game_category_id) {
            if (!game_category_id || this.isActive(game_category_id)) {
                this.game_category_id = game_category_id;
                this.updateGames();
            }
        },
        getGames() {
            let url = this.developer_games_route;

            const params = new URLSearchParams();

            if (this.game_category_id) {
                params.set('game_category_id', this.game_category_id);
            }

            if (this.current_page) {
                params.set('page', this.current_page);
            }

            if (params.size) {
                url += '?' + params;
            }

            const requestOptions = {
                method: 'get',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            };

            return fetch(url, requestOptions)
                    .then((response) => response.json())
        },
        updateGames() {
            this.current_page = 1;
            this.getGames()
                .then((response) => {
                    this.games = response.data;
                    this.last_page = response.meta.last_page;
                })
                .catch(error => console.log(error.message));
        },
        loadMore() {
            this.current_page++;
            this.getGames()
                .then((response) => {
                    response.data.forEach((game) => {
                        this.games.push(game);
                    });
                    this.current_page = response.meta.current_page;
                    this.last_page = response.meta.last_page;
                })
                .catch(error => console.log(error.message));
        },
    }
}
</script>
